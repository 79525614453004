<template>
  <base-info-card :title="title" :subtitle="subtitle" space="4" color="primary">
    <v-form ref="form" v-model="isValid" @submit.prevent="handleSubmitRegister">
      <base-text-field
        autocapitalize
        autofocus
        required
        v-model="formData.first_name"
        :label="$t('name')"
        :placeholder="$t('placeholderName')"
        :rules="rulesGenerics"
      />
      <base-text-field
        required
        type="email"
        v-model="formData.email"
        :label="$t('email')"
        :placeholder="$t('placeholderEmail')"
        :rules="rulesEmail"
      />

      <base-password-field
        v-model="formData.password"
        :label="$t('password')"
        :placeholder="$t('placeholderPassword')"
        :rules="rulesPassword"
      />

      <footer
        class="d-flex flex-column-reverse flex-md-row justify-space-between"
      >
        <base-btn
          :color="!theme.isDark ? 'primary' : 'white'"
          :to="getLinkToLogin"
        >
          {{ $t("iHaveAccount") }}
        </base-btn>
        <base-btn
          :color="!theme.isDark ? 'secondary' : 'white'"
          type="submit"
          class="my-2 my-md-0"
          :disabled="!isValid"
        >
          {{ $t("register") }}
        </base-btn>
      </footer>
    </v-form>
  </base-info-card>
</template>

<script>
import { i18n } from "@/plugins/i18n";
import { authRoutesName } from "@/router/names";
import { authCtrl } from "@/controllers";
import {
  getErrorPayloadForSnackbar,
  rulesName,
  rulesEmail,
  rulesPassword,
  rulesGenerics,
} from "@/helpers";

export default {
  name: "RegisterForm",
  inject: ["theme"],
  data: () => ({
    formData: { first_name: "", email: "", password: "" },
    isValid: false,
    rulesName,
    rulesEmail,
    rulesPassword,
    rulesGenerics,
  }),
  computed: {
    getLinkToLogin() {
      return { name: authRoutesName.LOGIN.name };
    },
  },
  methods: {
    async handleSubmitRegister() {
      try {
        const isValid = await this.$refs.form.validate();
        if (!isValid) return;
        const res = await authCtrl.handleRegister({ ...this.formData });
        res && this.$router.replace(authRoutesName.LOGIN);
      } catch (error) {
        const errorPayload = getErrorPayloadForSnackbar(error);
        this.$store.commit("user/OPEN_SNACKBAR", errorPayload);
      }
    },
  },
  props: {
    subtitle: String,
    title: {
      type: String,
      default: i18n.t("register"),
    },
  },
};
</script>
